import * as React from "react";
import Footer from "../../components/Footer/footer";
import { Head } from "../../components/Header/head";
import { Navbar } from "../../components/Navbar/navbar";
import panaderia1 from "../../assets/images/panaderia/panaderia1.jpg";
import panaderia2 from "../../assets/images/panaderia/panaderia2.jpg";

import panBlanco from "../../assets/images/panaderia/3.jpg";
import panMezclaHarinas from "../../assets/images/panaderia/4.jpg";
import panSemillas from "../../assets/images/panaderia/5.jpg";
import panEspelta from "../../assets/images/panaderia/2.jpg";
import panMegamix from "../../assets/images/panaderia/6.jpg";
import puntosVenta from "../../assets/images/panaderia/puntosVenta.jpg";

import "../../styles/panaderia.css";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { SSRProvider } from "@react-aria/ssr";
const Panaderia = () => {
  return (
    <SSRProvider>
      <Head title="Posada Los Jerónimos - Panaderia" />
      <Navbar />

      <section>
        <h2>Panaderia</h2>
        <p>
          La Panadería de los Jerónimos es un anexo dentro de la Posada de los
          Jerónimos.<p></p> En el restaurante de la Posada, siempre nos hemos
          decantado por la calidad de los productos, base de cualquier buena
          comida, muchos de ellos ecológicos (harinas, legumbres, pasta,
          patatas, tomate, parte de las verduras, aceites, azúcar, café, vinos)
          y otros muchos que, sin ser ecológicos, tenemos la garantía de la
          confianza con los productores a los que conocemos (cordero, carne de
          ternera, queso, etc).<p></p> Estando como estamos en tierra de muy
          buenas harinas, queríamos garantizar que nuestro pan, acompañante
          inseparable de la gastronomía ibérica, fuera también ecológico y,
          además, hecho por nosotros y por esas razones, decidimos dar el paso
          de hacer nuestra propia panadería.<p></p> Fue en el año 2015,
          compramos y montamos un horno de segunda mano, giratorio, de
          combustión indirecta y que se calienta con leña.<p></p> A partir de
          entonces, comenzamos a realizar pan, con el método de ensayo-error,
          muchos panes han salido mal, pero vamos mejorando, si bien no somos
          perfectos y todavía hay mucho que aprender. Eso sí, por supuesto y,
          como no podía ser de otra manera, con las mejores harinas ecológicas
          de Zamora.<p></p> En un principio, empezamos a hacer pan para nosotros
          y para el restaurante de la Posada, pero posteriormente fuimos a
          vender algo en pequeños comercios de Zamora y Salamanca.<p></p> Años
          después, hacemos 3 días a la semana, martes, viernes y domingos.
          <p></p>
          El bar-restaurante “Grial by Gusa”, cliente de nuestro pan, ha sido
          premiado con el primer premio de la categoría de votación popular en
          el XVI Festival de la Tapa De tapas X Zamora. Una satisfacción por
          Víctor y su equipo, pero también porque en su tapa del concurso, que
          era una sopa de ajo, se incluía nuestro pan blanco.
        </p>
      </section>
      <section className="panaderia-images">
        <div className="panaderia-images-wrapper">
          <img src={panaderia1} />
          <img src={panaderia2} />
        </div>
      </section>
      <section id="productos">
        <h2>Productos</h2>
        <div className="product-cards-wrapper">
          <div className="first-line-product-cards">
            <div className="product-card">
              <div className="product-image">
                <img src={panBlanco} />
              </div>
              <div className="product-title">Pan blanco</div>
              <div className="product-description">
                <ul>
                  <li>Harina de trigo blanca: 95%</li>
                  <li>Harina de trigo integral: 5%</li>
                  <li>Levadura natural de masa madre</li>
                  <li>Agua y sal marina</li>
                </ul>
                <span> Peso neto: 600g</span>
              </div>
            </div>
            <div className="product-card">
              <div className="product-image">
                <img src={panMezclaHarinas} />
              </div>
              <div className="product-title">Mezcla de Harinas</div>{" "}
              <div className="product-description">
                <ul>
                  <li>Harina de trigo integral: 65%</li>
                  <li>Harina de trigo blanca: 30%</li>
                  <li>Harina de tritordeum: 5%</li>
                  <li>Levadura natural de masa madre</li>
                  <li>Agua y sal marina</li>
                </ul>
                <span> Peso neto: 600g</span>
              </div>
            </div>
            <div className="product-card">
              <div className="product-image">
                <img src={panSemillas} />
              </div>
              <div className="product-title">Pan con semillas</div>{" "}
              <div className="product-description">
                <ul>
                  <li>Harina de trigo integral: 60%</li>
                  <li>Harina de trigo blanca: 25%</li>
                  <li>Harina de tritordeum: 15%</li>
                  <li>
                    Semillas de lino, girasol, sesamo, nueces, calabaza y
                    amapola
                  </li>
                  <li>Levadura natural de masa madre</li>
                  <li>Agua y sal marina</li>
                </ul>
                <span> Peso neto: 600g</span>
              </div>
            </div>
          </div>
          <div className="second-line-product-cards">
            <div className="product-card">
              <div className="product-image">
                <img src={panEspelta} />
              </div>
              <div className="product-title">Pan de espelta</div>{" "}
              <div className="product-description">
                <ul>
                  <li>Harina de espelta integral: 65%</li>
                  <li>Harina de espelta blanca: 35%</li>
                  <li>
                    Semillas de lino, girasol, sesamo, nueces, calabaza y
                    amapola
                  </li>
                  <li>Levadura natural de masa madre</li>
                  <li>Agua y sal marina</li>
                </ul>
                <span> Peso neto: 600g</span>
              </div>
            </div>
            <div className="product-card">
              <div className="product-image">
                <img src={panMegamix} />
              </div>
              <div className="product-title">Pan Megamix</div>{" "}
              <div className="product-description">
                <p>
                  Lo servimos sólo en el restaurante y se realiza mezclando una
                  parte de las masas de los panes anteriores. Tiene un
                  característico bicolor por la combinación de las distintas
                  masas.
                </p>
                <span> Peso neto: 2kg</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="puntos-venta">
        <h2>Puntos de venta</h2>
        <img src={puntosVenta} />
      </section>
      <Footer slim />
    </SSRProvider>
  );
};

export default Panaderia;
